/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { ProductType } from 'Component/Product/Product.config';
import { ProductPriceComponent as SourceProductPrice } from 'SourceComponent/ProductPrice/ProductPrice.component';

import { DisplayProductPricesInCatalog } from './ProductPrice.config';

import './ProductPrice.style';

/**
 * Product price
 * @class ProductPrice
 * @namespace Satisfly/Component/ProductPrice/Component */
export class ProductPriceComponent extends SourceProductPrice {
    renderOldPrice() {
        const {
            price: {
                originalPrice: {
                    value: originalPriceValue,
                    valueFormatted: originalPriceFormatted,
                } = {},
            } = {},
            discountPercentage,
            isSchemaRequired,
            variantsCount,
        } = this.props;

        if (discountPercentage === 0 || originalPriceValue === 0) {
            return null;
        }

        return (
            <div block="ProductPrice" elem="HighPriceWrapper">
                <del
                  block="ProductPrice"
                  elem="HighPrice"
                  mods={ { oldPrice: true } }
                  aria-label={ __('Old product price') }
                  itemProp={ isSchemaRequired && variantsCount > 1 ? { itemProp: 'highPrice' } : null }
                >
                    { originalPriceFormatted }
                </del>
            </div>
        );
    }

    renderDefaultPrice(defaultLabel = null) {
        const {
            price: { finalPrice = {}, finalPriceExclTax = {} } = {},
            label,
        } = this.props;

        return (
            <>
                { this.renderOldPrice() }
                { this.renderPriceWithOrWithoutTax(finalPrice, finalPriceExclTax, defaultLabel || label) }
            </>
        );
    }

    renderPriceWithOrWithoutTax(
        basePrice,
        taxPrice,
        label,
    ) {
        const {
            storeConfig: {
                tax_display_type,
            } = {},
        } = window;

        if (tax_display_type === DisplayProductPricesInCatalog.INCL_TAX) {
            return this.renderPrice(basePrice, label);
        }

        if (tax_display_type === DisplayProductPricesInCatalog.EXCL_TAX) {
            return this.renderPrice(taxPrice, label);
        }

        return (
            <>
                { this.renderPrice(basePrice, label) }
            </>
        );
    }

    getCurrentPriceSchema() {
        const {
            isSchemaRequired,
            price: {
                finalPrice: {
                    value: contentPrice = 0,
                } = {},
            } = {},
            isProductPage,
        } = this.props;

        return isSchemaRequired && isProductPage ? { itemProp: 'lowPrice', content: contentPrice } : {};
    }

    renderSchema() {
        const { isSchemaRequired, isProductPage } = this.props;

        if (isSchemaRequired && isProductPage) {
            const { itemProp = null, content = null } = this.getCurrencySchema();

            return (
                <meta itemProp={ itemProp } content={ content } />
            );
        }

        return null;
    }

    renderPrice(price, label) {
        const {
            discountPercentage,
            isProductPage,
            priceType,
        } = this.props;

        const {
            value: priceValue,
            valueFormatted: priceFormatted = 0,
            originalPrice: {
                value: originalPriceValue,
            } = {},
        } = price;

        const { itemProp = null, content = null } = this.getCurrentPriceSchema();

        // Use <ins></ins> <del></del> to represent new price and the old (deleted) one
        const PriceSemanticElementName = discountPercentage > 0 ? 'ins' : 'span';

        if ((!priceValue && priceValue !== 0)) {
            return null;
        }

        const isPromo = !(discountPercentage === 0 || originalPriceValue === 0);
        const priceLabel = discountPercentage !== 0 && priceType !== 'simple' ? __('from') : label;

        return (
            <PriceSemanticElementName block="ProductPrice" elem="Price" mods={ { wrapper: true, isPromo } }>
                { this.renderPriceBadge(priceLabel) }
                { content && itemProp && <meta itemProp={ itemProp } content={ content } /> }
                <span
                  block="ProductPrice"
                  elem="PriceValue"
                >
                    { priceFormatted }
                </span>
                { isProductPage && (
                    <div block="ProductActions" elem="PriceVat">
                        <span>{ __('Including VAT') }</span>
                    </div>
                ) }
            </PriceSemanticElementName>
        );
    }

    render() {
        const {
            price: {
                finalPrice,
                originalPrice,
                finalPrice: {
                    value: finalPriceValue = 0,
                } = {},
            } = {},
            priceType,
            isPreview,
            discountPercentage,
            mix,
        } = this.props;

        if (!finalPrice || !originalPrice) {
            return this.renderPlaceholder();
        }

        const { [priceType]: renderer } = this.pricePreviewRenderMap;

        return (
            <div
              block="ProductPrice"
              mods={ { hasDiscount: discountPercentage !== 0, isPreview } }
              mix={ mix }
              aria-label={ `Product price: ${finalPriceValue}` }
            >
                { isPreview && renderer && renderer() }
                { (!isPreview || !renderer) && this.renderDefaultPrice() }
                { priceType !== ProductType.BUNDLE && this.renderTierPrice() }
                { this.renderSchema() }
            </div>
        );
    }
}

export default ProductPriceComponent;

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    ProductPriceContainer as SourceProductPriceContainer,
} from 'SourceComponent/ProductPrice/ProductPrice.container';

/**
 * Product price
 * @class ProductPrice
 * @namespace Satisfly/Component/ProductPrice/Container */
export class ProductPriceContainer extends SourceProductPriceContainer {
    static propTypes = {
        ...super.propTypes,
        isProductPage: PropTypes.bool,
    };

    static defaultProps = {
        ...super.defaultProps,
        isProductPage: false,
    };

    containerProps() {
        const {
            price: {
                price,
                originalPrice,
                configuration,
                price: {
                    finalPrice: {
                        currency: priceCurrency,
                    },
                    discount: {
                        percentOff: discountPercentage = 0,
                    } = {},
                } = {},
            },
            isPreview,
            isSchemaRequired,
            label,
            mix,
            variantsCount,
            priceType,
            displayTaxInPrice,
            isProductPage,
        } = this.props;

        if (!price || !originalPrice) {
            return {};
        }

        return {
            price,
            originalPrice,
            configuration,
            tierPrice: this.getMinTierPrice(priceCurrency),
            priceCurrency,
            discountPercentage,
            isPreview,
            isSchemaRequired,
            label,
            mix,
            variantsCount,
            priceType,
            displayTaxInPrice,
            isProductPage,
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPriceContainer);

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    ProductWishlistButtonContainer as SourceContainer,
} from 'SourceComponent/ProductWishlistButton/ProductWishlistButton.container';

import ProductWishlistButton from './ProductWishlistButton.component';

/** @namespace Satisfly/Component/ProductWishlistButton/Container */
export class ProductWishlistButtonContainer extends SourceContainer {
    render() {
        const { isWishlistButtonLoading } = this.state;

        return (
            <ProductWishlistButton
              isLoading={ isWishlistButtonLoading }
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductWishlistButtonContainer);

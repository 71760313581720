/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import HeartIcon from 'Component/HeartIcon';
import { ProductWishlistButtonComponent as SourceComponent } from 'SourceComponent/ProductWishlistButton/ProductWishlistButton.component';

import './ProductWishlistButton.override.style';

/** @namespace Satisfly/Component/ProductWishlistButton/Component */
export class ProductWishlistButtonComponent extends SourceComponent {
    renderButton() {
        const {
            isInWishlist, isDisabled, mix, isLoading,
        } = this.props;

        return (
            <button
              block="ProductWishlistButton"
              elem="Button"
              mods={ { isInWishlist, isDisabled, isLoading } }
              disabled={ isLoading }
              mix={ { block: 'Button', mix } }
              title={ this.getTitle() }
              onClick={ this.onClick }
            >
                <HeartIcon isActive={ isInWishlist } />
            </button>
        );
    }

    renderContent() {
        return (
            <div block="ProductWishlistButton">
                { this.renderButton() }
            </div>
        );
    }
}

export default ProductWishlistButtonComponent;
